export default {
  name: 'barcodes',
  data() {
    return {
      barcodes: []
    }
  },
  components: {},
  created() {
    if (localStorage.getItem('barcodes') && localStorage.getItem('barcodes').length > 0) {
      this.barcodes = JSON.parse(localStorage.getItem('barcodes'));
    }
  },
  mounted() {},
  watch: {},
  methods: {
    printPage() {
      window.print();
    }
  },
  destroyed() {},
}
